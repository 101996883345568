import { Component, OnInit } from "@angular/core";
import { FormControl, FormGroup } from "@angular/forms";
import { ToastrService } from "ngx-toastr";
import { DEFAULT_PAGE_NO, DEFAULT_PAGE_SIZE } from "src/app/constant/constant";
import { CUSTOM_REPORT } from "src/app/constant/responseMessage";
import { CustomReportsModel, CustomReportType } from "src/app/models/custom-reports-model";
import { CustomReportService } from "src/app/services/custom-report.service";
import { Router } from '@angular/router';
@Component({
    selector: 'app-custom-reports',
    templateUrl: './custom-reports.component.html',
    styleUrls: ['./custom-reports.component.css']
})

export class CustomReportsComponent implements OnInit {
    filterFormGroup: FormGroup<{ reportName: FormControl<string> }> = new FormGroup<{ reportName: FormControl<string> }>({
        reportName: new FormControl('', { nonNullable: true }),
    });
    //Pagination
    pageSize: number = DEFAULT_PAGE_SIZE;
    pageNo: number = DEFAULT_PAGE_NO;
    totalPages: any;
    page: number = 1;
    tableData: CustomReportsModel[] = [];
    title: string = CUSTOM_REPORT;
    serialNumber: number = 1;

    constructor(private customReportAPI: CustomReportService, private toastr: ToastrService, private router: Router) { }

    ngOnInit(): void {
        this.getCustomReportList();
    }

    selectPage(page: string) {
        this.page = parseInt(page, 10) || 1;
        this.pageNo = this.page - 1
        this.getCustomReportList();
    }

    formatInput(input: HTMLInputElement) {
        const FILTER_PAG_REGEX = /[^0-9]/g;
        input.value = input.value.replace(FILTER_PAG_REGEX, '');
    }

    onChangePagination() {
        this.pageNo = this.page - 1
        this.serialNumber = this.page == 1 ? 1 : (this.page - 1) * 10 + 1;
        this.getCustomReportList();
    }

    gotoPreview(row: CustomReportType) {
        this.router.navigateByUrl('/layout/custom-report-design', { state: { edit: false, id: row.id } });
    }

    onDelete(row: CustomReportType) {
        this.customReportAPI.deleteCustomReportById(row.id).subscribe(res => {
            if (res.status == 0) {
                this.toastr.success(res.message);
                this.getCustomReportList();
            } else {
                this.toastr.error(res.message);
            }
        }, err => {
            this.toastr.error(err);
        });
    }

    gotoEdit(row: any) {
        this.router.navigateByUrl('/layout/custom-report-design', { state: { edit: true, id: row.id } });
    }

    getCustomReportList() {
        let reportName = this.filterFormGroup.value.reportName ?? '';
        this.customReportAPI.getCustomReportList(reportName, this.pageNo, this.pageSize).subscribe((res) => {
            if (res.status == 0) {
                if (res.responseObject) {
                    this.tableData = res.responseObject.reportData;
                    this.totalPages = res.responseObject.totalCount;
                } else {
                    this.tableData = []
                    this.totalPages = null
                }
            } else {
                this.toastr.error(res.message);
            }
        }, err => {
            console.log('Error while fetching report list : \n', err);
            this.toastr.error(err.message);
        });
    }

    clearFilter() {
        this.filterFormGroup.controls.reportName.reset();
        this.getCustomReportList();
    }

    searchByName() {
        if (this.filterFormGroup.value.reportName == '') {
            this.toastr.warning('Search key is required');
        } else {
            this.getCustomReportList();
        }
    }
};


