import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { baseURL, VERSION } from '../constant/constant';
import { CustomReportType } from '../models/custom-reports-model';
import { FilterJson, TableHeader } from 'src/app/constant/export-types';
import { getTimeZoneHeader } from '../constant/globalFunction';

@Injectable({
  providedIn: 'root'
})
export class CustomReportService {

  constructor(private http: HttpClient) { }

  getCustomReportData(filterJson: FilterJson[], pageNo: number, pageSize: number) {
    return this.http.put<CustomerReportTableData>(`${baseURL}customReport/${VERSION}/data?pageNo=${pageNo}&pageSize=${pageSize}`, filterJson);
  }

  getCustomReportList(reportName: string, pageNo: number, pageSize: number) {
    if (reportName == '') {
      return this.http.get<ReportListResponse>(`${baseURL}customReport/${VERSION}/list?pageNo=${pageNo}&pageSize=${pageSize}`);
    } else {
      return this.http.get<ReportListResponse>(`${baseURL}customReport/${VERSION}/list?pageNo=${pageNo}&pageSize=${pageSize}&reportName=${reportName}`);
    }
  }

  CreateNewCustomReport(data: any) {
    return this.http.post<CreateUpdateResponse>(`${baseURL}customReport/${VERSION}`, data);
  }

  UpdateCustomReport(data: any) {
    return this.http.put<CreateUpdateResponse>(`${baseURL}customReport/${VERSION}`, data);
  }

  getCustomReportById(id: number) {
    return this.http.get<CustomReport>(`${baseURL}customReport/${VERSION}?id=${id}`);
  }

  deleteCustomReportById(id: number) {
    return this.http.delete<CustomReport>(`${baseURL}customReport/${VERSION}?id=${id}`);
  }

  exportCustomReportData(id: number) {
    return this.http.get<ExportResponse>(`${baseURL}customReport/${VERSION}/export?id=${id}`, { headers: getTimeZoneHeader() });
  }

}

type ReportListResponse = {
  status: number;
  message: string;
  responseObject: { reportData: CustomReportType[], totalCount: number };
}

type CreateUpdateResponse = {
  status: number;
  message: string;
  responseObject: { id: number, name: string, filterJson: TableHeader[] };
}

type CustomReport = {
  status: number;
  message: string;
  responseObject: CustomReportType;
}

type CustomerReportTableData = {
  status: number;
  message: string;
  responseObject: { header: string[], data: any[], totalCount: number };
}

type ExportResponse = {
  status: number,
  message: string,
  responseObject: {
    id: number,
    name: string,
    studyId: string,
    filterJson: FilterJson[]
  }
}